import React from "react"

import Layout from "@src/components/Layout"
import OG from "@src/icons/og/OG_Landing.jpeg"
import Callout from "@src/components/Callout"
import getFeaturesUrl from "@src/utils/getFeaturesUrl"
import getDocsUrl from "@src/utils/getDocsUrl"

import PlaygroundIcon from "@src/icons/v2/playground.svg"
import AdaptiveCmsIcon from "@src/icons/v2/adaptive-cms.svg"
import GraphqlRestIcon from "@src/icons/v2/graphql-rest.svg"
import SocialSigninIcon from "@src/icons/v2/social-signin.svg"
import InstantDeploymentsIcon from "@src/icons/v2/instant-deployments.svg"
import ScheduledTriggersIcon from "@src/icons/v2/scheduled-triggers.svg"

// import Splash from "./sections/Splash"
import Row from "../../../V2/components/Row"
import Features from "../../../V2/Features/components/Features"
import ImageRow from "../../../V2/Features/components/ImageRow"
import Splash from "../../../V2/Features/components/Splash"
import * as styles from "../../../../layouts/V2/PlatformOverview/PlatformOverview.module.scss"
import DotBG from "../../../V2/components/DotBG"
import useMobile from "../../../V2/utils/isMobile"

const IndexPage = ({ location }) => {
  const featuresUrl = getFeaturesUrl(location)
  const docsUrl = getDocsUrl(location)
  const isMobile = useMobile()
  return (
    <Layout
      title="Canonic | Build internal tools for agile teams"
      description="The simplest way for agile teams to build internal tools. Focus on critical tasks and automate the rest."
      url="https://canonic.dev"
      location={location}
      ogImage={OG}
    >
      <Splash
        title="A complete solution to build complex internal tools."
        subtitle="A low-code solution for teams to focus on critical tasks and automate the rest."
        buttonText="Start for free"
      />
      <Row className={styles.row}>
        <DotBG
          height={isMobile ? "133%" : "100%"}
          offset={isMobile ? "-7%" : "-10%"}
        />
        <section>
          <h2 className={styles.title}>Bring simplicity to complexity</h2>
          <ImageRow
            title="A frontend builder that just works"
            subtitle="Using a simple drag-n-drop interface coupled with powerful bindings allow just about any type of tool to be built."
            features={[]}
          >
            <Embed id="6ohwln9f2o" />
          </ImageRow>
          <ImageRow
            title="Build and design multi step workflows"
            subtitle="Add business logic using a simple mind-map like interface. Make API calls, connect with services, add conditionals, loops and branches."
            reverse
          >
            <Embed id="5hgh6jvs2i" />
          </ImageRow>
          <ImageRow
            title="Interact with hundreds of services and datasources"
            subtitle="We connect with all your favorites! From connecting to your postgres database to pulling data from Salesforce, interact with them all."
          >
            <Embed id="s6ai7o9nzu" />
          </ImageRow>
        </section>
      </Row>
      <Row>
        <h2 className={styles.title}>All the features you can need</h2>
        <Features
          numPerRow={3}
          features={[
            {
              title: "Connect multiple databases",
              icon: ScheduledTriggersIcon,
              subtitle:
                "Connect multiple database to your project. We support Postgres, MySQL, and MongoDB databases. ",
            },
            {
              title: "Frontend Builder",
              icon: PlaygroundIcon,
              subtitle:
                "Drag and drop components to build your UI. Add your own custom components.",
            },
            {
              title: "Custom Domains",
              icon: InstantDeploymentsIcon,
              subtitle:
                "Connect your project to the domain of your choice and deploy it securely",
            },
            {
              title: "User Authentication",
              icon: SocialSigninIcon,
              subtitle:
                "Prebuilt user authentication available to add with 3rd party sign support as well",
            },
            {
              title: "GraphQL & Rest APIs",
              icon: GraphqlRestIcon,
              subtitle:
                "Get APIs with your project and integrate with your existing infrastructure",
            },
            {
              title: "Adaptive CMS",
              icon: AdaptiveCmsIcon,
              subtitle:
                "The CMS automatically adapts to your data schema to make it more intuitive..",
            },
          ]}
        />
      </Row>
      <Callout location={location} title="platformOverview" />
    </Layout>
  )
}

function Embed({ id }) {
  React.useEffect(() => {
    window._wq = window._wq || []
    window._wq.push({
      id,
      options: {
        fitStrategy: "cover",
        videoFoam: false,
      },
    })
  }, [])
  return (
    <>
      <iframe
        src={`https://fast.wistia.net/embed/iframe/${id}?fitStrategy=cover`}
        title="Workflows Video"
        allow="autoplay; fullscreen"
        allowtransparency="true"
        frameborder="0"
        scrolling="no"
        class="wistia_embed"
        name="wistia_embed"
        msallowfullscreen
        width="100%"
        height="100%"
      ></iframe>
      <script
        src="https://fast.wistia.net/assets/external/E-v1.js"
        async
      ></script>
    </>
  )
}

export default IndexPage
